// Import necessary libraries
import React from 'react';
import { Container, Segment } from 'semantic-ui-react';
import { Logo, Breadcrumbs } from '@plone/volto/components';
import { useLocation } from 'react-router-dom';

// Define the Header component
const Header = () => {
  // Get the current pathname from the router
  const location = useLocation();
  const pathname = location.pathname;

  // Return the JSX structure for the header
  return (
    <Segment // Wrap everything in a Segment component
      basic // Apply the "basic" style to the Segment
      className="header-wrapper" // Add a custom class for styling
      role="banner" // Set the semantic role to "banner"
    >
      <Container> 
        <div className="header"> 
          <div className="logo-nav-wrapper"> 
            <div className="logo"> 
              <Logo /> 
            </div>
            <div className="breadcrumbs" style={{ maxWidth: '400px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              <Breadcrumbs pathname={pathname} /> 
            </div>
          </div>
          <div className="tools-search-wrapper"> 
            <div className="login"> 
              <a // Define the login link
                aria-label="login" // Set the accessibility label
                href="/login?return_url=" // Specify the login URL
                style={{ color: '@teal' }} // Apply custom color with variable
              >
                LOG IN
              </a>
            </div>
          </div>
          </div>
        </Container>
      </Segment>
    );
};

// Export the Header component for use in other components
export default Header;