/** Volto Project Configuration
 * The recommended way for configuring a Volto project is using an add-on.
 * The project should only be considered as boilerplate, and all the code and
 * configuration should happen and be placed in add-ons.
 *
 * Both configuring directly and placing code in a project is discouraged, and might be
 * removed at some point from Volto.
 *
 * The local project is left for backwards compatibility for existing projects.
 */

// [Internal] All the imports of modules required for the configuration *must* happen
// here BEFORE the following line :172.26.0.2
import '@plone/volto/config';

const config = {
  settings: {
    // ... other settings ...
    INTERNAL_API_PATH: 'http://investment-economics.in:8080/investmenteconomics', // Update with your Plone container's IP and site ID
  },
};

export default config;
