import React from 'react';
import { Container, Divider, Segment } from 'semantic-ui-react';

const Footer = () => {
  return (
    <Segment
      role="contentinfo"
      textAlign="center"
      id="footer"
      aria-label="Footer"
    >
      <Container>
        <Divider horizontal>
          <p style={{ color: 'gray' }}>Copyright & Powered by Plone & Python</p>
        </Divider>
      </Container>
    </Segment>
  );
};

export default Footer;