import React from 'react';

const Logo = () => (
  <div className="logo">
    <span className="investment">INVESTMENT</span>
    <span className="economics">economics</span>
  </div>
);

export default Logo;
